<template>
  <div class="viewcontent" v-if="translations">
    <Title
      :text="opportunity.fullName"
      background="primary_bg_color"
      type="subtitle"
    />
    <div class="left">
      <img
        :src="files + opportunity.pictures"
        :alt="opportunity.fullName"
        class="img"
      />
    </div>
    <div class="right">
      <div v-if="opportunity.description">
        <p
          v-for="(item, index) in opportunity.description.split(
            /<br\s*\/?>|\n/g
          )"
          :key="index"
          class="description"
        >
          {{ item }}
        </p>
      </div>
      <div
        @click="linkTo('exhibitor')"
        class="mouseHover primary_color text hovertext"
        v-if="opportunity.exhibitorName"
      >
        {{
          replaceTranslation({
            type: 'offered',
            text: opportunity.exhibitorName,
          })
        }}
      </div>

      <div class="form" v-if="logged && opportunity">
        <div class="itemcont" v-if="opportunity.fullName">
          <span class="previewLeft">
            {{ opportunity.fullName }}
          </span>
          <span class="previewRight primary_color">
            <i :class="defineIcon"></i>
          </span>
        </div>
        <hr />
        <div class="itemcont">
          <label>
            <span class="previewLeft importantValue">
              {{ translations.form_number_investors }}
            </span>
            <span class="previewRight importantValue">
              {{ opportunity.total_users }}
            </span>
          </label>
        </div>
        <div v-if="opportunity.subtype == 1">
          <div class="itemcont">
            <label>
              <span class="previewLeft">
                {{ translations.form_min_investment }}
              </span>
              <span class="previewRight importantValue">
                {{
                  opportunity.minimum_investment
                    ? opportunity.minimum_investment
                    : '-'
                }}
              </span>
            </label>
          </div>
          <div class="itemcont">
            <label>
              <span class="previewLeft">
                {{ translations.form_max_investment }}
              </span>
              <span class="previewRight importantValue">
                {{
                  opportunity.hardlimit_investment
                    ? opportunity.hardlimit_investment
                    : '-'
                }}
              </span>
            </label>
          </div>
          <div class="itemcont">
            <label>
              <span class="previewLeft">
                {{ translations.form_raised_invested }}
              </span>
              <span class="previewRight importantValue">
                {{
                  opportunity.raised_investment
                    ? opportunity.raised_investment
                    : '-'
                }}
              </span>
            </label>
          </div>
          <div class="itemcont">
            <label>
              <span class="previewLeft importantValue">
                {{ translations.form_total_invested }}
              </span>
              <span class="previewRight importantValue">
                {{
                  opportunity.total_invested ? opportunity.total_invested : '-'
                }}
              </span>
            </label>
          </div>
        </div>
        <hr />
        <form
          @submit.prevent="processForm"
          v-if="
            !opportunity.alreadyinvested &&
              (opportunity.total_invested_num <
                opportunity.raised_investment_num ||
                opportunity.subtype == 4)
          "
        >
          <div v-if="opportunity.subtype == 3" class="itemcont">
            <label for="volume_min">
              <span class="previewLeft">
                {{ translations.form_volume_min }}
              </span>
              <input
                class="border_category previewRight"
                type="number"
                name="volume_min"
                v-model.number="opportunityForm.volume_min"
              />
            </label>
          </div>
          <div v-if="opportunity.subtype == 3" class="itemcont">
            <label for="volume_max">
              <span class="previewLeft">
                {{ translations.form_volume_max }}
              </span>
              <input
                class="border_category previewRight"
                type="number"
                name="volume_max"
                v-model.number="opportunityForm.volume_max"
              />
            </label>
          </div>
          <div v-else-if="opportunity.subtype == 5" class="itemcont">
            <label for="invested_sum">
              <span class="previewLeft">
                {{ translations.form_invested_sum }}
              </span>
              <input
                class="border_category previewRight"
                type="number"
                name="invested_sum"
                v-model.number="opportunityForm.invested_sum"
              />
            </label>
          </div>
          <div v-else-if="opportunity.subtype == 2" class="itemcont">
            <label for="agreements">
              <span class="previewLeft">{{
                translations.form_agreements
              }}</span>
              <input
                class="border_category previewRight"
                type="text"
                name="agreements"
                v-model.trim="opportunityForm.agreements"
              />
            </label>
          </div>
          <div v-else-if="opportunity.subtype == 4" class="itemcont">
            <span class="previewLeft">{{ translations.form_comments }}</span>
            <textarea
              class="border_category "
              name="comments"
              v-model.trim="opportunityForm.comments"
            ></textarea>
          </div>
          <div class="checkbox">
            <input
              name="regaccepttou"
              class="contrast_color"
              type="checkbox"
              v-model="checked"
              required
            />
            <label for="regaccepttou" class="fourth_color">
              <a class="terms primary_color" href="'https://www.lipsum.com/'">
                {{ translations.label_terms_accept }}</a
              >
            </label>
          </div>
          <div class=" contrast_bg_color">
            <button class="btn primary_bg_color contrast_color" type="submit">
              {{ translations.btn_participate }}
            </button>
          </div>
        </form>
        <div v-else-if="opportunity.alreadyinvested" class="primary_color">
          {{ translations.warning_already_invested }}
        </div>
        <div v-else>
          {{ translations.warning_objective_investment }}
        </div>
      </div>
      <ProgressBar
        :sum="opportunity.total_invested_num"
        :max="opportunity.raised_investment_num"
        v-if="opportunity.optype == 1 && opportunity.raised_investment_num > 0"
      />
      <ExhibitorTools
        :size="50"
        :info="{
          code: opportunity.code,
          type: 'opportunity',
          cards: opportunity.cards,
        }"
        v-if="opportunity.estatus >= 2"
      />
    </div>

    <div class="extrainfo" v-if="opportunity.subtype">
      <Title
        background="primary_bg_color"
        type="subtitle"
        :text="translations.title_opportunity_info"
      />
      <ExhibitorInfo
        :titles="checkTitles"
        :values="checkValues"
        :key="opportunity.subtype"
      />
    </div>
  </div>
</template>

<script>
  import Title from '@/components/Title.vue'
  import ProgressBar from '@/components/ProgressBar.vue'
  import ExhibitorInfo from '@/components/ExhibitorInfo.vue'
  import ExhibitorTools from '@/components/ExhibitorTools.vue'
  import { mapActions, mapState } from 'vuex'
  import router from '../router'

  export default {
    name: 'Opportunity',
    data() {
      return {
        opportunityForm: {
          volume_min: null,
          volume_max: null,
          invested_sum: null,
          agreements: null,
          comments: null,
        },
        checked: false,
      }
    },
    components: {
      Title,
      ExhibitorInfo,
      ProgressBar,
      ExhibitorTools,
    },
    computed: {
      ...mapState(['opportunity', 'files', 'logged', 'translations']),
      defineIcon() {
        let icon = ''
        switch (this.opportunity.subtype) {
          case 1:
          case 5:
            icon = 'fas fa-chart-line'

            break
          case 2:
            icon = 'fas fa-ship'
            break
          case 3:
            icon = 'fas fa-handshake'
            break
          case 4:
            icon = 'fas fa-hands-helping'
            break
        }
        return icon
      },
      checkTitles() {
        let result = []
        switch (this.opportunity.subtype) {
          case 1:
          case 5:
            result.push(
              this.translations.label_minimum_investment,
              this.translations.label_info_hard_investment,
              this.translations.label_raised_investment,
              this.translations.label_irr_1y,
              this.translations.label_irr_3y,
              this.translations.label_irr_5y
            )
            break
          case 2:
            result.push(
              this.translations.label_info_target,
              this.translations.label_min_percentage,
              this.translations.label_pl_division_type,
              this.translations.label_withdrawal_type,
              this.translations.label_authority_type
            )
            break
          case 3:
            result.push(this.translations.label_info_target_markets)
            break
          case 4:
            result.push(
              this.translations.label_minimum_wage,
              this.translations.label_max_salary
            )
            break
        }
        result.push(this.translations.label_estimated_duration)
        return result
      },
      checkValues() {
        let result = {}
        switch (this.opportunity.subtype) {
          case 1:
          case 5:
            result = {
              minimum_investment: this.opportunity.minimum_investment,
              hardlimit_investment: this.opportunity.hardlimit_investment,
              raised_investment: this.opportunity.raised_investment,
              irr_1y: this.opportunity.IRR_1y + ' %',
              irr_3y: this.opportunity.IRR_3y + ' %',
              irr_5y: this.opportunity.IRR_5y + ' %',
            }
            break
          case 2:
            result = {
              target_audience: this.opportunity.target_audience,
              txt_min_percentage: this.opportunity.min_percentage + ' %',
              txt_pl_division_type: this.opportunity.pl_division_type,
              txt_withdrawal_type: this.opportunity.withdrawal_type,
              txt_authority_type: this.opportunity.withdrawal_type,
            }
            break
          case 3:
            result = { target_market: this.opportunity.target_market }
            break
          case 4:
            result = {
              minimum_investment: this.opportunity.minimum_investment,
              hardlimit_investment: this.opportunity.hardlimit_investment,
            }
            break
        }
        if (this.opportunity.subtype != 4) {
          result['estimatedDuration'] =
            this.opportunity.estimated_duration + ' years'
        } else {
          result['estimatedDuration'] =
            this.opportunity.estimated_duration == 0
              ? this.translations.type_permanet
              : this.opportunity.estimated_duration
        }
        return result
      },
    },
    methods: {
      ...mapActions(['getOpportunity', 'saveOpportunity', 'resetOpportunity']),
      processForm() {
        let save = false
        if (this.logged && this.checked) {
          switch (this.opportunity.subtype) {
            case 5:
              let min_invest = Number(
                this.opportunity.minimum_investment.split(' ')[0]
              )
              if (this.opportunity.total_invested === null) {
                this.opportunity.total_invested = 0
              } else {
                let total_invest = this.opportunity.total_invested.split(' ')[0]
              }

              // console.log(this.opportunityForm.volume_max)
              // console.log(this.total_invest)
              if (min_invest <= this.opportunityForm.invested_sum) {
                // if (
                //   !isNaN(this.opportunityForm.total_invested) &&
                //   this.volume_max + this.total_invested >= this.invested_sum
                // ) {
                //   console.log('es valido')
                // } else {
                //   console.log('no es valido')
                // }
                save = true
              } else {
                console.log('you put less than the minimum')
              }
              break
            case 1:
            case 2:
            case 3:
            case 4:
              save = true
              break
          }
          if (save) {
            this.opportunityForm.opportunity_code = this.opportunity.code
            this.saveOpportunity(this.opportunityForm)
          } else {
            console.log('something went wrong')
          }
        }
      },
      linkTo(value) {
        switch (value) {
          case 'exhibitor':
            router.push('/exhibitor/' + this.opportunity.exhibitorCode)
            break
        }
      },
      replaceTranslation(value) {
        let result = ''
        if (this.translations) {
          switch (value.type) {
            case 'other':
              result = this.translations.title_other_products
              result = result.replace('{var0}', value.text[0])
              result = result.replace('{var1}', value.text[1])
              break
            case 'offered':
              result = this.translations.title_presentation_offered.replace(
                '{var}',
                value.text
              )
              break
            case 'categories':
              result = this.translations.btn_showmore_numeric_categories.replace(
                '{var}',
                value.text
              )
              break
            case 'similar':
              result = this.translations.title_brands_similar_product.replace(
                '{var}',
                value.text
              )
              break
          }
        }

        return result
      },
      addTranslations() {
        if (this.translations) {
        }
      },
    },
    async created() {
      await this.getOpportunity(this.$route.params.id)
      this.addTranslations()
    },
    watch: {
      $route(to, from) {
        if (to.name == 'Opportunity') {
          this.getOpportunity(this.$route.params.id)
        }
      },
    },
    beforeUnmount() {
      this.resetOpportunity()
    },
  }
</script>

<style scoped>
  textarea {
    height: 100px !important;
    text-align: left;
    width: 100%;
  }
  .img {
    max-width: 100%;
    height: auto;
  }
  .left,
  .right {
    width: 48%;
    display: inline-block;
    padding: 5px;
    vertical-align: top;
  }
  .description {
    text-align: left;
  }
  .checkbox input {
    width: 16px;
    height: 16px;
    margin: auto;
    margin-right: 5px;
    /* display: inline-block;
    vertical-align: middle; */
  }
  .checkbox label {
    width: 100%;
    vertical-align: text-bottom;
  }
  .form {
    border-style: solid;
    border-width: 1px;
    padding: 10px;
  }
  .btn {
    width: 95%;
    margin: 0;
    padding: 0;
  }
  input[type='text'],
  input[type='number'] {
    border-width: 1px;
    border-style: solid;
  }
  .previewLeft,
  .previewRight {
    width: 49%;
    float: left;
    height: 20px;
  }
  .previewLeft {
    text-align: left;
  }
  .previewRight {
    text-align: right;
  }

  label {
    width: 100%;
    margin: 3px 0;
  }
  .itemcont {
    display: flex;
  }
  .importantValue {
    font-weight: bold;
  }
  .hovertext:hover {
    font-weight: bold;
  }
  @media screen and (max-width: 900px) {
    .right,
    .left {
      width: 100%;
      padding: 0;
    }
    .description {
      padding: 0 10px;
    }
  }
</style>
